import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/main/app.module';
import { environment } from './environments/environment';
import { logger } from './app/main/util/Logger';

if (environment.enableSentry) {
  Sentry.init({
    dsn: "https://1d19bc0cfaf14f3232b678347e714895@o4508741509971968.ingest.de.sentry.io/4508787958612048",
    environment: environment.production ? 'Production' : 'Development',
    release: environment.buildId,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", "https://api.ew.digitalp.com.au"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    attachStacktrace: true
  });
}

logger.info(`Starting Application. BuildId[${environment.buildId}]`);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if (window['ngRef']) {
      window['ngRef'].destroy();
    }
    window['ngRef'] = ref;

    // Otherwise, log the boot error
  })
  .catch(err => console.error(err));
